<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Platby - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <h4 class="text-black mt-4 mb-3"><strong>Nastavitelné podle jazyka</strong></h4>

        <a-form-item
          label="Název"
          :validate-status="error('phe_name') ? 'error' : ''"
          :help="error('phe_name') || ''"
        >
          <a-input placeholder="Název" v-decorator="['phe_name', {rules: [{max: 80, message: 'Název nemůže být delší než 80 znaků!'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
        </a-form-item>

        <h4 class="text-black mt-4 mb-3"><strong>Ostatní</strong></h4>

        <a-form-item
          label="+ % z celkového nákupu"
          :validate-status="error('pty_add_percentage') ? 'error' : ''"
          :help="error('pty_add_percentage') || ''"
        >
          <a-input-number
            :min="0.000"
            :max="100.000"
            :step="0.001"
            :precision="3"
            style="width: 100%;"
            v-decorator="[`pty_add_percentage`, { initialValue: 0.000, rules: [{ required: true, message: '+ % z celkového nákupu musí být vyplněná!' }]}]"
          />
        </a-form-item>

        <a-form-item
          label="Cena (Kč)"
          :validate-status="error('pty_price') ? 'error' : ''"
          :help="error('pty_price') || ''"
        >
          <a-input-number
            :min="0.00"
            :step="0.01"
            :precision="2"
            style="width: 100%;"
            v-decorator="[`pty_price`, { initialValue: 0.00, rules: [{ required: true, message: 'Cena musí být vyplněná!' }]}]"
          />
        </a-form-item>

        <a-form-item label="Aktivní?">
          <a-checkbox v-decorator="['pmt_is_active', { valuePropName: 'checked', initialValue: true, rules: [{ required: true, message: 'Aktivní musí být vyplněno!' }] }]"/>
        </a-form-item>

        <a-form-item label="Dobírka?">
          <a-checkbox v-decorator="['pmt_is_cash_on_delivery', { valuePropName: 'checked', initialValue: true }]"/>
        </a-form-item>

        <my-form-image-item
          title="Obrázek"
          subject="Platba"
          decorator-name="image_id"
          :image="thumbImage"
          :image-next-count="thumbImageNextCount"
          :images="thumbImages"
          :on-image-switch-change="onThumbImageSwitchChange"
          :on-next-images-click="onNextThumbImagesClick"
          :form="form"
        ></my-form-image-item>

        <a-form-item
          label="Vyber platební službu"
          :validate-status="error('service_id') ? 'error' : ''"
          :help="error('service_id') || ''"
        >
          <a-select :allowClear="true" placeholder="-- Vyber platební službu --" :loading="services.loading" :options="paymentServices.map(x => { return { value: x.id, label: '# ' + x.id + ' | ' + x.name } })" v-decorator="['service_id']"></a-select>
        </a-form-item>

        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError()) || (thumbImage.switch && !isThumbImageSelected(false))">Přidat</a-button>
        </div>
      </a-form>
    </div>
  </section>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../../services/forms'
import myFormImageItem from '@/components/MyComponents/Gallery/my-form-image-item'
import thumbImageMixin from '@/components/MyComponents/Gallery/thumb-image-mixin'

export default {
  components: {
    ActionTools, LanguageTab, myFormImageItem,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  mixins: [thumbImageMixin],
  data() {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/ecommerce/payment',
          title: 'Seznam',
        },
      ],
      services: {
        loading: false,
      },
      item: {
        languages: [],
      },
    }
  },
  computed: {
    language: function () {
      return this.$store.getters['language/active']
    },
    paymentServices: function () {
      return this.$store.getters['paymentServices/allItems']
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('payment/post', this.getData())
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    initData() {
      this.services.loading = true
      Promise.all([
        this.$store.dispatch('paymentServices/list'),
      ]).catch(() => {})
        .finally(() => {
          this.services.loading = false
        })
    },
    getData() {
      return {
        image_id: !this.isThumbImageSelected(false) ? null : parseInt(this.form.getFieldValue('image_id')),
        service_id: this.form.getFieldValue('service_id') === undefined ? null : this.form.getFieldValue('service_id'),
        pmt_is_cash_on_delivery: this.form.getFieldValue('pmt_is_cash_on_delivery'),
        pmt_is_active: this.form.getFieldValue('pmt_is_active'),
        pty_add_percentage: this.form.getFieldValue('pty_add_percentage'),
        pty_price: this.form.getFieldValue('pty_price'),
        languages: this.item.languages.filter(x => x.phe_name !== undefined && x.phe_name.trim().length > 0).map(x => {
          return {
            lge_id: x.lge_id,
            phe_name: x.phe_name,
          }
        }),
      }
    },
    handleChange(oldLanguage) {
      const foundObj = { payment: false }
      const newObj = {
        payment: {
          lge_id: oldLanguage,
          phe_name: this.form.getFieldValue('phe_name'),
        },
      }
      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = newObj.payment
          foundObj.payment = true
          return true
        }
      })
      if (!foundObj.payment) {
        this.item.languages.push(newObj.payment)
      }
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
  },
  created() {
    this.initData()
  },
  watch: {
    language(newValue, oldValue) {
      if (oldValue !== null) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        const foundObj = { payment: this.item.languages.find(x => x.lge_id === newValue) }
        const newObj = foundObj.payment === undefined ? { phe_name: undefined } : { phe_name: foundObj.payment.phe_name }
        this.form.setFieldsValue(newObj)
        this.form.validateFields()
      }
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
